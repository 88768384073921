<template lang="pug">
v-container.loginContainer(fluid, fill-height)
    .bg
    v-row(align='center', justify='center')
        v-fade-transition(hide-on-leave)
            v-col(text-center, cols='12', md='10', lg='8', align-self='center')
                v-card(light, elevation='20')
                    v-card-text
                        v-row
                            v-col(cols='6')
                                v-row(justify='center', align='center')
                                    v-col
                                        v-img.mt-6.mb-0.logo(src='/DCLogo.jpg', height='64', contain)
                                v-row(justify='center', align='center')
                                    v-col(cols='10')
                                        span.text-h4.brand--text Welcome to the customer portal
                                v-row(justify='center', align='center')
                                    v-col(cols='10')
                                        ul
                                            li View training resources
                                            li Manage InfoScan updates
                                            li Manage communications settings
                            v-col(cols='6')
                                v-form(ref='loginForm')
                                    v-row(justify='center', align='center')
                                        v-col(cols='10')
                                    v-row(justify='center', align='center')
                                        v-col(cols='10')
                                            v-text-field.pt-4(color='brand', v-model='username', label='Username', required, autofocus, light, @keyup.enter='login', :error-messages='errors', @input='clearError', outlined, dense)
                                    v-row(justify='center', align='center')
                                        v-col(cols='10')
                                            v-text-field(color='brand', type='password', v-model='password', label='Password', required, light, @keyup.enter='login', :error-messages='errors', @input='clearError', outlined, dense)
                                    v-row(justify='center', align='center')
                                        v-col(cols='10')
                                            v-btn.brandContrast--text(color='brand', block, large, @click='login', :loading='loggingIn') Login
                                    v-row
                                        v-col.ml-12
                                            span Need help?
                                            br
                                            a.brand--text.text-decoration-underline(href='https://dorsettcontrols.com/support/', target='_blank') Dorsett Controls Support
                                            br
                                            a.brand--text.text-decoration-underline(@click='goToSecurityBulletins') Security Bulletins
                        v-row
                            v-col(justify='center', align='center')
                                v-btn(v-for='(social, i) in socials', :color='social.color', icon, target='_blank', :href='social.link', :key='i')
                                    svg.socialIcon(role='img', viewbox='0 0 24 24', xmlns='http://www.w3.org/200/svg', :style='{fill: social.color}', fab)
                                        path(:d='social.svgData')
</template>

<script>
export default {
    data () {
        return {
            username: '',
            password: '',
            errors: [],
            loggingIn: false
        };
    },
    computed: {
        socials () {
            return [
                {
                    link: 'https://www.linkedin.com/company/dorsettcontrols',
                    icon: 'linkedin',
                    color: '#0072b1',
                    svgData: 'M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z'
                },
                {
                    link: 'https://www.youtube.com/@DorsettControls',
                    icon: 'mdi_youtube',
                    color: '#ff0000',
                    svgData: 'M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z'
                },
                {
                    link: 'https://www.facebook.com/DorsettControls',
                    icon: 'facebook',
                    color: '#3b5998',
                    svgData: 'M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z'
                }
            ];
        }
    },
    methods: {
        clearError () {
            this.errors = [];
        },
        async login () {
            this.loggingIn = true;
            const result = await this.axios.post(`/dccsapi/auth/login`, {
                username: this.username, 
                password: this.password
            });
            console.log('login result', result);
            if (result.data.err) {
                this.errors = [result.data.err];
                console.log('error logging in:', result.data.err);
                this.loggingIn = false;
            } else {
                this.$store.commit('setApiKey', result.data.apiKey);
                this.$store.commit('setUser', result.data.token);
                await this.$store.dispatch('startup', false);
                if (this.$route.query?.redirect) {
                    this.$router.push(this.$route.query.redirect);
                } else {
                    this.$router.push('/home');
                }
            }
        },
        goToSecurityBulletins () {
            this.$router.push('/security-bulletins');
        }
    }
};
</script>
<style lang="scss">
.loginContainer {
    background-image: linear-gradient(-60deg, #009eda 50%, #00355c 50%);
    height: 100%;
    width: 100%;
}
.socialIcon {
    height: 24px;
    width: 24px;
}
</style>
